import { ProjectStatusEnum } from '../../project/project.model';
import { FormStatusEnum } from '../../form/form.model';
import { TemplateRef } from '@angular/core';
import { UserStatusEnum } from '../../user/user-status.enum';

export enum CardColor {
  Teal = 'teal',
  Orange = 'orange',
  Neutral = 'neutral',
}

export enum CardType {
  DataSet = 'dataset',
  CustomerProject = 'customerProject',
  Assignment = 'assignment',
  Group = 'group',
  Form = 'form',
  Role = 'role',
  Project = 'project',
  User = 'user',
}

export interface CardSettingsDTO {
  cardBackgroundColor: string;
  cardButtonBackgroundColor: string;
  cardButtonForegroundColor: string;
  cardButtonOnHoverBackgroundColor: string;
  cardButtonOnHoverForegroundColor: string;
  cardForegroundPrimaryColor: string;
  cardForegroundSecondaryColor: string;
}

export interface BaseCardSettings {
  settings?: {
    cardSettings?: CardSettingsDTO;
  };
}

export interface CardSettings {
  buttonBackgroundColor: string;
  buttonForegroundColor: string;
  backgroundColor: string;
  foregroundPrimaryColor: string;
  foregroundSecondaryColor: string;
  buttonOnHoverBackgroundColor: string;
  buttonOnHoverForegroundColor: string;
}

export interface CardModel<T> {
  card: {
    name: string;
    nameTemplate?: TemplateRef<any>;
    type: CardType;
    settings: CardSettings | null;
    status?: FormStatusEnum | ProjectStatusEnum | UserStatusEnum;
    subtitle?: string;
    subtitleTemplate?: TemplateRef<any>;
    color?: CardColor,
    isLocked?: boolean,
  };
  original: T;
}

